
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyAAFUf2E7opFiw36sO80E0YeRvEep6X2SA",
  authDomain: "psluxuryhotel-69a6f.firebaseapp.com",
  projectId: "psluxuryhotel-69a6f",
  storageBucket: "psluxuryhotel-69a6f.appspot.com",
  messagingSenderId: "519706306622",
  appId: "1:519706306622:web:8b1f2b2eecdaf7827c496f",
  measurementId: "G-87WR8PQDJH"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;