import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import {Link} from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import "./Room.css";

import ScrollToTop from "react-scroll-to-top";
// import { Typewriter } from 'react-simple-typewriter' 
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";



function Corporate () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [corporate, setCorporate] = useState([])
      const navigate = useNavigate()
  
      const corporateCollectionRef = collection(db, "corporate");
      useEffect(() => {
  
          const getCorporate = async () => {
              const data = await getDocs(corporateCollectionRef);
              setCorporate(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getCorporate()
      }, [])
    return (
      
<>
<Navbar/>

<br/>
<ScrollToTop smooth top="100" color="Gold" />
<br></br>
<br/>
<br/>
<br/>
<div className="album py-1">
        
            <div className="container">

                <div className="row">
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Home</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/room" >Room AC</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Seminar" >Seminar</Link></li>
    
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Reception" href="#">Reception</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Conference" >Conference</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Restaurant" href="#">Restaurant & Cafe</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Contactus" href="#">Contact</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Corporate</li>
    
  </ol>
</nav>
</div>
</div>
</div>

{/* <h2 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "maroon", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={60}
    deleteSpeed={90}
    delaySpeed={1000}
    words={[ "Bala", "M. B. Dhar and Son Jewellers", "Best Jewellery Collections"]}
    />
</span>
</b></h2> */}
<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {corporate.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
      
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((psluxuryhotel) => { return (
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={psluxuryhotel.img}  alt="psluxuryhotel_corporate"/>
                            <div className="card-body">
                                
                            <h6 className="card-text mb-2"> <b>{psluxuryhotel.title}</b></h6>
                            <p className="card-text mb-2"> {psluxuryhotel.des}</p>
                                < h5 style={{color: "Darkblue"}}className="card-text"> ₹{psluxuryhotel.price} </h5>
                
                                <div className="d-flex justify-content-between align-items-center mb-2">
                              
                              <a href="tel: +91 03482314135" className="btn btn btn-warning btn-sm ">Call Book Now</a>
                              <ReactWhatsapp number="+91 7501739299" class="btn btn-success btn-sm" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg>  Whatsapp</ReactWhatsapp>
                          </div>
                                <button type="button" className="btn btn btn-dark me-1">
                                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>  < FacebookShareButton url="https://www.psluxuryhotel.com/corporate" className="me-2" quote={"Best Hotel & Restaurant in Berhampore"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.psluxuryhotel.com/corporate" className="me-2" quote={"Best Hotel & Restaurant in Berhampore"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.psluxuryhotel.com/corporate" className="me-2" quote={"Best Hotel & Restaurant in Berhampore"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.psluxuryhotel.com/corporate" className="me-2" quote={"Best Hotel & Restaurant in Berhampore"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.psluxuryhotel.com/corporate" quote={"Best Hotel & Restaurant in Berhampore"} hastag={"#WebApp"}
>
 <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                               
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={psluxuryhotel.com/room.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Corporate;



