
import React from 'react'
import { Link } from "react-router-dom";
import "./Navbar.css";
import Pslogo from "../images/logopslh.jpg";


function Navbar() {
  return (
    <div>
      <div className="fixed-top">
        <div className="psluxury">
          <div className="album py-1">

            <div className="container">

              <div className="row">
                <div className="col-md-10">
                  <div className="psluxuryhotelberhampore">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <marquee><h5 className='mt-2' style={{ color: "white" }} > Our Services: Rooftop Family Restaurant & Cafe | Room AC | Seminar | Corporate Event | Reception | Conference  </h5></marquee>

                    </div>
                  </div>
                </div>
                {/* <div className="col-md-2">
                    <Link to="/Review">
                  <button className='review'>Review</button>
                  </Link>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow">
          <div className="container-fluid">
            <Link to="/">
              <img src={Pslogo} height="38px" className="me-2" width="100px" alt="ps-luxury-hotel-logo" />
            </Link>
            <Link to="/Reception">
              <button className="btn btn-outline-light btn-sm">Book Your Special Day</button>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" style={{ color: "Gold" }} className="nav-link active" aria-current="page" >Home</Link>
                </li>

                <div class="dropdown">
                  <button className="dropbtn">Room <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg></button>
                  <div class="dropdown-content">

                    <li><Link to="/Standard" className="dropdown-item" >Standard Room </Link></li>
                    <li><Link to="/Deluxe" className="dropdown-item" > Deluxe Room</Link></li>
                    <li><Link to="/super-deluxe" className="dropdown-item" > Supur Deluxe Room</Link></li>

                  </div>
                </div>
                {/* <li className="nav-item">
          
          <Link to="/room" style={{color:"Gold"}} className="nav-link " aria-current="page" >Room Book</Link>
        
        </li> */}
                <li className="nav-item">

                  <Link to="/corporate" style={{ color: "Gold" }} className="nav-link " aria-current="page" >Corporate Event</Link>

                </li>

                <li className="nav-item">

                  <Link to="/Seminar" style={{ color: "Gold" }} className="nav-link " aria-current="page" >Seminar</Link>

                </li>
                <li className="nav-item">

                  <Link to="/Conference" style={{ color: "Gold" }} className="nav-link " aria-current="page">Conference </Link>

                </li>
                <li className="nav-item">

                  <Link to="/Reception" style={{ color: "Gold" }} className="nav-link " aria-current="page" >Reception</Link>

                </li>

                <li className="nav-item">

                  <Link to="/Restaurant" style={{ color: "Gold" }} className="nav-link " aria-current="page" >Restaurant & Cafe Menu</Link>

                </li>

                <li className="nav-item">

                  <Link to="/Gallery" style={{ color: "Gold" }} className="nav-link " aria-current="page" >Gallery</Link>

                </li>



                <li className="nav-item">
                  <Link to="/Contactus" style={{ color: "Gold" }} className="nav-link active" aria-current="page" >Contact Us</Link>
                </li>
              </ul>



            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
