import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AddBook from "../Addbook";
import BooksList from "../BooksList";
import Navbar from "../inc/Navbar";


function Review() {
  const [bookId, setBookId] = useState("");

  const getBookIdHandler = (id) => {
    console.log("The ID of document to be edited: ", id);
    setBookId(id);
  };
  return (
    <>
    <Navbar/>
  
    <br/>

    <br/>   <br/>
    <div className="backc1">
  
    <br/>
    <center><h1 style={{color:"white"}} >PS Luxury Hotel <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg></h1></center>
    <center><h3  style={{color:"white"}}><b>Review </b> <div className="spinner-grow spinner-grow-sm" role="status">
  <span class="visually-hidden">Loading...</span>
</div></h3></center>
  
 
   
    </div>
    <br/>
    <br/>
      {/* <Navbar bg="dark" variant="dark" className="header">
        <Container>
          <Navbar.Brand href="#home">Library - Firebase CRUD</Navbar.Brand>
        </Container>
      </Navbar> */}

      <Container >
        <Row>
          <Col>
            <AddBook id={bookId} setBookId={setBookId} />
          </Col>
        </Row>
      </Container>
      <br/>
      <br/>
      <Container>
        <Row>
          <Col>
            <BooksList getBookId={getBookIdHandler} />
          </Col>
        </Row>
      </Container>

      {/* <AddBook/>
      <BooksList/> */}
    </>
  );
}

export default Review;