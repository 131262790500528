import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

import { Link } from "react-router-dom";
import "./Room.css";
import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
// import { Typewriter } from 'react-simple-typewriter' 
import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";



function Gallery() {
  const [setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(9);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [gallery, setGallery] = useState([])
  const navigate = useNavigate()

  const galleryCollectionRef = collection(db, "gallery");
  useEffect(() => {

    const getGallery = async () => {
      const data = await getDocs(galleryCollectionRef);
      setGallery(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getGallery()
  }, [])
  return (

    <>
    
      <div className="back">

        <ScrollToTop smooth top="100" color="Gold" />

        <br />
       
        
        
        {/* <h2 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "maroon", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={60}
    deleteSpeed={90}
    delaySpeed={1000}
    words={[ "Bala", "M. B. Dhar and Son Jewellers", "Best Jewellery Collections"]}
    />
</span>
</b></h2> */}

        <div className="album py-3">

          <div className="container">

            <div className="row">
              {gallery.filter((val) => {
                if (setsearchTerm === "") {
                  return val;
                } {
                  return val;
                }
              }).slice(pagination.start, pagination.end).map((psluxuryhotel) => {
                return (
                  <div className="col-md-4 mt-8" >
                    
                      <div className="imagesc1">
                        <img className="card-img-top mb-3 mt-3" src={psluxuryhotel.img} alt="psluxuryhotel_room" />
                        <div className="card-body">

                      <center>
                          <button type="button" className="btn btn btn-dark btn-sm me-1">
                            Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                              <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                            </svg>
                          </button>  < FacebookShareButton url="https://www.psluxuryhotel.com/room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                          >
                            <FacebookIcon size={37} round={true} />
                          </FacebookShareButton>

                          <  WhatsappShareButton url="https://www.psluxuryhotel.com/room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                          >
                            <WhatsappIcon size={37} round={true} />
                          </ WhatsappShareButton>

                          <  TwitterShareButton url="https://www.psluxuryhotel.com/room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                          >
                            <TwitterIcon size={37} round={true} />
                          </ TwitterShareButton>

                          <  LinkedinShareButton url="https://www.psluxuryhotel.com/room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                          >
                            <  LinkedinIcon size={37} round={true} />
                          </ LinkedinShareButton>

                          <  EmailShareButton url="https://www.psluxuryhotel.com/room" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                          >
                            <  EmailIcon size={37} round={true} />
                          </ EmailShareButton>
                          </center>
                        </div>
                      </div>
                  

                  </div>
                )
              })}
            </div>
            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={gallery.length}
                /> */}


          </div>
        </div>
        <br/>
      </div>

    </>

  );
}

export default Gallery;



