
import React from 'react'
import {Link } from "react-router-dom";

import Navbar from '../inc/Navbar';
import Gallery from "../pages/Gallery";


function Gallerypage() {
  return (
  <>
    <Navbar />

    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div className="album py-1">
        
            <div className="container">

                <div className="row">
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Home</Link></li>
   
    
    <li className="breadcrumb-item active" aria-current="page">Gallery</li>
    
  </ol>
</nav>
</div>
</div>
</div>
<Gallery/>
  </>
  )
}

export default Gallerypage
