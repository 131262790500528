import React from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Conferance from "../images/CONFARENCE.jpg";
import Room from "../images/rooms.jpg";
import Restaurant  from "../images/menups.jpg";

import Corporate  from "../images/conf.jpg";


import Reception from "../images/recep.jpg";
import Corporatesmeeting from "../images/corporates.jpg";


import "./Allservices.css";

import { Link } from 'react-router-dom';


function Silck() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    return (
        <div>


            <div className="animat">
                <Slider {...settings}>



                    <div>
                        <div className="card mb-4 border-White box ">
                            <Link to="/Conference">
                                <img src={Conferance} className="card-img-top" alt="Conferance" />
                            </Link>
                            <div className="card-body">

                                <p style={{ color: "maroon" }} className="card-text mb-0"><small>Conference</small></p>
                                <Link to="/Conference">
                                    <p className="card-text"> <small> <span>Click Now<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </div>
                        </div>
                    </div>

                    <div>

                        <div className="card mb-4 border-White box ">
                            <Link to="/Restaurant">
                                <img src={Restaurant} className="card-img-top" alt="Restaurant" />
                            </Link>
                            <div className="card-body">

                                <p style={{ color: "maroon" }} className="card-text mb-0"><small>Restaurant</small></p>
                                <Link to="/Restaurant">
                                    <p className="card-text"> <small> <span>Click Now<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="card mb-4 border-White box ">
                            <Link to="/room">
                                <img src={Room} className="card-img-top" alt="Room" />
                            </Link>
                            <div className="card-body">

                                <p style={{ color: "maroon" }} className="card-text mb-0"><small>Room</small></p>
                                <Link to="/room">
                                    <p className="card-text"> <small> <span>Click Now<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </div>
                        </div>
                    </div>


                    <div className="card mb-4 border-White box ">
                        <Link to="/Corporate">
                            <img src={Corporate} className="card-img-top" alt="Necklace" />
                        </Link>
                        <div className="card-body">

                            <p style={{ color: "maroon" }} className="card-text mb-0"><small>Seminar</small></p>
                            <Link to="/Corporate">
                                <p className="card-text"> <small> <span>Click Now<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg></span></small></p> </Link>
                        </div>
                    </div>



                    <div className="card mb-4 border-White box ">
                        <Link to="/Corporate">
                            <img src={Corporatesmeeting} className="card-img-top" alt="Corporate" />
                        </Link>
                        <div className="card-body">

                            <p style={{ color: "maroon" }} className="card-text mb-0"><small>Corporate</small></p>
                            <Link to="/Corporate">
                                <p className="card-text"> <small> <span>Click Now<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg></span></small></p> </Link>
                        </div>
                    </div>


                    <div className="card mb-4 border-White box ">
                        <Link to="/Reception">
                            <img src={Reception} className="card-img-top" alt="Reception" />
                        </Link>
                        <div className="card-body">

                            <p style={{ color: "maroon" }} className="card-text mb-0"><small>Reception</small></p>
                            <Link to="/Reception">
                                <p className="card-text"> <small> <span>Click Now<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg></span></small></p> </Link>
                        </div>
                    </div>





                    <div>


                    </div>



                    <div>



                    </div>


                </Slider>
            </div>

        </div>
    )
}



export default Silck
