
import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from "./components/pages/Contactus";
import Room from "./components/pages/Room";
import Corporate from "./components/pages/Corporate";
import Reception from "./components/pages/Reception";
import Seminar from "./components/pages/Seminar";
import Cafe from "./components/pages/Cafe";
import Conference from "./components/pages/Conference";

import Gallerypage from "./components/pages/Gallerypage";
import Sdulux from './components/pages/Sdulux';
import Standard from './components/pages/Standard';
import Duluxroom from './components/pages/Duluxroom';
import Review from './components/pages/Review';

function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
         <Route axact path="/Contactus" element={<Contactus/>}/>
         <Route axact path="/room" element={<Room/>}/>
         <Route axact path="/corporate" element={<Corporate/>}/>
         <Route axact path="/Reception" element={<Reception/>}/>
         <Route axact path="/Seminar" element={<Seminar/>}/>
         <Route axact path="/Restaurant" element={<Cafe/>}/>
          
         <Route axact path="/Conference" element={<Conference/>}/>
         <Route axact path="/Gallery" element={<Gallerypage/>}/>
         <Route axact path="/super-deluxe" element={<Sdulux/>}/>
         <Route axact path="/Standard" element={<Standard/>}/>
         <Route axact path="/Deluxe" element={<Duluxroom/>}/>
         <Route axact path="/Review" element={<Review/>}/>

      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
