
import React from 'react'
import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider";

import Receptionhall from "../images/RECEPTION.jpg";
import Dining from "../images/DINING.jpg";
import Conferancehall from "../images/CONFARENCE.jpg";
import { Link } from "react-router-dom";
import Parking from "../images/parking-area.jpg";

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Lift from "../images/lift-video.mp4"


import Menu from "../pages/Menu";
import ReactPlayer from "react-player";

import Building from "../images/building.jpeg";
import cafebar from "../images/cafe_bar.jpeg";
import Gallery from "../pages/Gallery";

import Allservices from "../pages/Allservices";



// import psluxuryhotelbuilding from "../images/building.mp4";

import "./Home.css";

function Home() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <Slider />



            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <div className="col-md-8" >
                            <div class="card border-priamry text-white mb-3" >
                                <div class="card-header"><h4 style={{ color: "maroon" }}><center><b> PS LUXURY HOTEL <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg></b></center></h4></div>
                                <div class="card-body ">
                                    <Link to="/Reception">
                                        <div class="btn-grad20  mb-2">  Banquet Hall Booking <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg></div></Link>

                      

                                    <div className="d-grid gap-3">
                                    <Link to="/super-deluxe">
                                        <div class="btn-grad20  mb-2">  Luxury Room Booking <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg> </div></Link>
                                    </div>



                                    <Link to="/Seminar">
                                        <div class="btn-grad20  mb-2"> Seminar <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg> </div></Link>

                                        <Link to="/Conference">
                                        <div class="btn-grad20  mb-2"> Conference <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg> </div></Link>
                                        <Link to="/Restaurant">
                                        <div class="btn-grad20  mb-2"> Top View Restaurant <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg> </div></Link>



                                        <Link to="/corporate">
                                        <div class="btn-grad20  mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                        </svg> Corporate Event <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg> </div></Link>


                                 

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">

                            <div className="card ">
                                <center>
                                    <iframe className="iframe-fluid" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fsabsamytv%2Fvideos%2F295701043266134%2F&show_text=true&width=560&t=0" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <center>
            <Link to="/Review">
                  <button className='review'>Review</button>
                  </Link>
                  </center>
            <Allservices />
            <div className="psluxuyhotel">
                <center><h1><b >Welcome To PS Luxury Hotel</b></h1></center>

                <center><h3>OUR FACILITY</h3></center>

            </div>




            <div className="album py-2">

                <div className="container">

                    <div className="row">

                        <div className="col-md-4 mt-8" >
                            <div className="card mb-4 border-White box shadow">
                            <img src={Parking} className="card-img-top mb-3" alt="bestluxuryhotelberhampore" />

                                <div className="card-body">

                                    <h5 style={{ color: "maroon" }} className="card-text mb-0"> <center> Huge Parking Area</center></h5><br />



                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 mt-6" >
                            <div className="card mb-4 border box shadow">
                                <div className="shrink">
                                    <img src={Receptionhall} className="card-img-top mb-3" alt="bestluxuryhotelberhampore" />
                                </div>
                                <div className="card-body">
                                    <h5 style={{ color: "maroon" }} className="card-text mb-0"><center> <b>Reception Hall</b></center></h5><br />

                                </div>
                            </div>

                        </div>
                        {/* style={{textAlign: "center"}} */}
                        <div className="col-md-4" >
                            <div className="card mb-4 border-white box shadow">
                                <div className="hovare">
                                    <img src={Dining} className="card-img-top mb-3" alt="Dininghallbookberhampore" />
                                </div>
                                <div className="card-body">
                                    <h5 style={{ color: "maroon" }} className="card-text mb-0"><center>Dining Hall</center></h5><br />

                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 mt-6" >
                            <div className="card mb-4 border-white box shadow">
                                <div className="hovare">
                                    <img src={Conferancehall} className="card-img-top mb-3" alt="psluxuryhotel" />
                                </div>
                                <div className="card-body">
                                    <h5 style={{ color: "maroon" }} className="card-text mb-0"><center>Conference Hall</center></h5><br />

                                </div>
                            </div>

                        </div>


                        <div className="col-md-4 mt-6" >
                            <div className="card mb-4 border-white box shadow">
                            <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>

                <source src={Lift} height="250px" type="video/webm" />

              </Video>
                                <div className="card-body">
                                    <h5 style={{ color: "maroon" }} className="card-text mb-0"><center><b>Lift</b></center></h5>
                                    <br />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <br />



            <div className="radiusboook">
                <div className="album py-1">

                    <div className="container">

                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <img src={Building} className="img-fluid" alt="psluxurybboking" />

                            </div>
                            <div className="col-md-6">
                                <img src={cafebar} className="img-fluid" alt="psluxurybboking" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <br />
            <Menu />
            <div className="back">
                <br />
                <div className="album py-0">

                    <div className="container">

                        <div className="row">
                            <h2><b>PS Luxury Hotel Gallery</b></h2>
                        </div>
                    </div>
                </div>
            </div>
            <Gallery />

        </div>

    )
}

export default Home
